import React, { useState } from "react";
import { constore } from "../utils/mixins/mixin";
import AppInput from "./partials/AppInput";

const handleImageRetouchedSubmit = (e, props) => {
    e.preventDefault();
    let data = e.target.getData();
    return props.services
        .apiCurd("Frontend", {
            tablename: "enquiry",
            action: "create",
            browser_fingerprint: props.services.getBrowserFingerprint().hash(),
            ...data,
            enquiry_type: "free_image_request",
            ...(props.payload || {}),
        })
        .then(({ data }) => {
            // return Promise.resolve();
            e.target.reset();
            if (props.page == "") {
                alert(
                    <div>
                        <div style={{ marginBottom: "10px" }}>
                            Your Image has been Submitted, Please Stay Tuned or
                            Wait for Sometime.
                        </div>
                    </div>
                );
            } else {
                alert("Image Uploaded Successfully");
            }
        });
};

const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!allowedExtensions.includes(file.type)) {
        alert("Only image files are allowed.");
        e.target.value = ''; 
      }
    }
  };

const onOptionsTransform = (props,data) => {

    let countryName = props.getProps("country.name") || "";
    data = data.map((item) => {
        item.label = item.label
            .split("{incountry}")
            .join(`${countryName}`)
            .split("{country}")
            .join(`in ${countryName}`);
        return item;
    });
    console.log("onOptionsTransform", { data, countryName });
    return data;
};

const handleImageSubmit = (event, props) => {
    event.preventDefault();  

    handleImageRetouchedSubmit(event, props).then(() => {
        $('#myModal').modal('hide');
        document.querySelector("#myModal .modal-header button").click();  
    });
};

const AppFreeRetouchedImage = constore(function (props) {
    const [isVisible, setIsVisible] = useState(true);
    const { data, service } = props;

    const handleClose = () => {
        setIsVisible(false);
      };
    // const downloadpath = service?"/pdfs/brochure/" + service.name + ".pdf":"/pdfs/common_brochure.pdf";
    // console.log({ AppInsights: data });
    return (
        isVisible && (
        <section>
            <div className="pt-0">
                <div className="call-to-action pt-0 pb-0">
                <div className="row" id="bgg">
                 <div className="col-md-8">
                    <h3 className="text-white font-24 pb-0 pl-0 d-flex align-items-baseline cust-w-70"
                        id="corp2"
                        style={{fontFamily: "cursive", justifyContent: "center"}}>
                        <div>
                          <marquee behavior="scroll" direction="right" scrollamount="8">
                            <span>Upload Your 1 Image and Get Retouched For Free!</span>
                          </marquee>
                        </div>
                    </h3>
                </div>
            <div className="col-md-4 d-flex align-items-baseline cust-w-70"
                style={{fontFamily: "Geneva, Verdana, sans-serif", fontWeight: "bold", marginTop: "15px"}}>
                <a id="flashuploadbtn"
                   href="#"
                   title="Click for Free Image"
                   data-toggle="modal"
                   data-target="#myModal"
                   className="combo-btn-with btn btn-colored btn-circle btn-theme-color-2 text-white text-center font-18">UPLOAD NOW
                </a>
            <div className="ml-auto" style={{ marginLeft: "auto" }}>
                <button type="button"
                        id="cl"
                        onClick={handleClose}
                        className="close"
                        style={{fontSize: "24px", padding:'10px'}}> X
                </button>
            </div>
        </div>
        </div>
        </div>
       </div>

            <div id="myModal" role="dialog" className="modal fade">
                <div className="modal-dialog">
                    <div
                        className="modal-content"
                        style={{
                            marginTop: "155px",
                            background:
                                "linear-gradient(105deg, #ff5a5a, #082cfb)",
                            
                        }}
                    >
                        <div className="modal-header form">
                            <button
                                type="button"
                                data-dismiss="modal"
                                className="close"
                            >
                                X
                            </button>
                            <h4
                                className="modal-title"
                                style={{
                                    fontFamily: "Geneva, Verdana, sans-serif",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: "white",
                                }}
                            >
                                Upload Your Image and Get Free Retouched Image
                            </h4>
                        </div>
                        <form
                            onSubmit={(e) => handleImageSubmit(e, props)}
                        >
                            <div
                                className="modal-body form"
                                style={{
                                    fontFamily: "Geneva, Verdana, sans-serif",
                                }}
                            >
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Enter Name"
                                    className="form-control"
                                    required={true}
                                />
                            </div>
                            <div
                                className="modal-body form"
                                style={{
                                    fontFamily: "Geneva, Verdana, sans-serif",
                                }}
                            >
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Enter Email"
                                    className="form-control"
                                    required={true}
                                />
                            </div>
                            <div
                                className="modal-body form"
                                style={{
                                    fontFamily: "Geneva, Verdana, sans-serif",
                                }}
                            >
                                <input
                                    type="text"
                                    name="phone"
                                    id="brochure_phone"
                                    placeholder="Enter Phone Number"
                                    className="form-control"
                                    required={true}
                                />
                            </div>
                            <div className="col-sm-12">
                                <AppInput
                                    type="select"
                                    choose="Service"
                                    name="service_id"
                                    refers="services"
                                    referselect="id as key,title as label"
                                    placeholder="Select Service"
                                    onOptionsTransform={(data)=>onOptionsTransform(props,data)}
                                    required={true}
                                ></AppInput>
                            </div>
                            <div className="col-sm-12">
                                <AppInput
                                    type="textarea"
                                    name="message"
                                    data-maxlen="200"
                                    placeholder="Enter Message(200 words limit)"
                                    // required={true}
                                ></AppInput>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group mb-10 text-black">
                                <input
                                    type="file"
                                    name="service_file_doc"
                                    placeholder="Please Upload File"
                                    required={true}
                                    accept="image/png, image/jpeg, image/jpg"
                                    onChange={handleFileChange}
                                />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <AppReCaptcha />
                            </div>
                            <div
                                id="ss"
                                className="modal-foote"
                                style={{
                                    fontFamily: "Geneva, Verdana, sans-serif",
                                    paddingLeft: "432px",
                                }}
                            >
                                <button
                                    type="submit"
                                    className="btn btn-primary brochure_submit"
                                >
                                    UPLOAD NOW
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
          )
    );
}, "AppFreeRetouchedImage");

export default AppFreeRetouchedImage;
