import { constore } from "../../utils/mixins/mixin";
import AppHomeSlider from "../../components/AppHomeSlider";
import AppHomeRequest from "../../components/AppHomeRequest";
import AppServiceList from "../../components/AppServiceList";
import AppKeyFeature from "./AppKeyFeature";
import AppGetStarted from "./AppGetStarted";
import AppFooter from "../../components/AppFooter";
import AppRegistrationForm from "../../components/AppRegistrationForm";
import AppWhyYouChoose from "./AppWhyYouChoose";
import AppPricingDetailsMain from "../home/AppPricingDetailsMain";
import AppUploadPhotosTitleLabel from "../uploadphotos/AppUploadPhotosTitleLabel";

export default constore(function () {
  return (
    <>
      <AppHomeSlider />
      <AppHomeRequest />
      <AppServiceList />
      <AppUploadPhotosTitleLabel/> 
      <AppPricingDetailsMain/>
      <AppWhyYouChoose />
      <AppKeyFeature />
      <AppUploadPhotosTitleLabel/>
      <AppGetStarted />
      <AppRegistrationForm title="Make a Enquiry Now!" needRequest={true} payload={{"action":"create",tablename:"enquiry", "enquiry_type":"enquiry"}} />
      <AppFooter />
    </>


  )
}, "home");














